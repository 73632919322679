const Dashboards = () => import(/* webpackChunkName: "dashboards" */ '@/pages/Dashboards.vue');
const CreateDashboard = () =>
    import(/* webpackChunkName: "dashboards" */ '@/components/dashboards/creation-form/CreateDashboard.vue');
const CreateDashboardFolder = () =>
    import(/* webpackChunkName: "dashboards" */ '@/components/dashboards/creation-form/CreateDashboardFolder.vue');
const DashboardsFolderPanel = () =>
    import(/* webpackChunkName: "dashboards" */ '@/components/dashboards/DashboardsFinder/DashboardsFolderPanel.vue');

export default {
    name: 'customer.dashboards-list',
    path: '',
    component: Dashboards,
    meta: {
        title: 'DASHBOARDS_TITLE',
    },
    redirect: { name: 'dashboards-all' },
    children: [
        {
            path: 'new',
            components: {
                fullPageLayer: CreateDashboard,
            },
            children: [
                {
                    name: 'dashboards-new',
                    path: '',
                },
                {
                    name: 'dashboards-library-new',
                    path: 'library',
                },
                {
                    name: 'dashboards-new-from-template',
                    path: 'template',
                },
                {
                    name: 'dashboards-folders-new',
                    path: 'folder',
                },
            ],
        },
        {
            name: 'dashboards-new-folder',
            path: 'new-folder',
            components: {
                fullPageLayer: CreateDashboardFolder,
            },
        },
        {
            name: 'dashboards-all',
            path: '',
            components: {
                layer: DashboardsFolderPanel,
            },
        },
        {
            name: 'dashboards-template',
            path: 'template',
            components: {
                layer: DashboardsFolderPanel,
            },
        },
        {
            name: 'dashboards-report',
            path: 'report',
            components: {
                layer: DashboardsFolderPanel,
            },
        },
    ],
};
